<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center" align="center">
      <v-col cols="auto">
        <v-container class="fill-height">
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <v-progress-circular
                :size="50"
                :color="error ? 'error' : 'primary'"
                indeterminate
              />
            </v-col>
          </v-row>
          <v-row justify="center" align="center">
            <v-col cols="auto">
              <h3 v-if="!error">{{ $t('flights.loading_flight_copy') }}</h3>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FlightsMixins from './mixins/FlightsMixins';
import HotJarMixins from '@/core/mixins/HotJarMixins';

export default {
  name: 'AddFlightsPage',
  mixins: [FlightsMixins, HotJarMixins],

  data() {
    return {
      error: null,
      showFlightLandingFF: null,
    };
  },

  created() {
    this.checkFlightLandingFF();
  },

  methods: {
    redirect() {
      let routeName;
      let route;
      if (this.hasContextFlights) {
        if (
          this.selectedContextFlight &&
          Object.values(this.selectedContextFlight).length
        ) {
          routeName = 'concierge';
        } else {
          routeName = this.flightRouteName;
        }
        route = { name: routeName };

        // pass through stage query parameters to the concierge if they exist
        if (routeName === 'concierge' && 'stage' in this.$route.query) {
          route['query'] = { stage: this.$route.query.stage };
        }
        this.$router.push(route).catch((err) => {});
      } else {
        routeName = this.flightRouteName;
        route = { name: routeName };
        this.$router.push(route).catch((err) => {});
      }
    },

    async checkFlightLandingFF() {
      this.showFlightLandingFF = await this.$ldclient.variation(
        'flights-landing-page',
        false,
        true
      );
      this.$ldclient.$on('change:flights-landing-page', (value) => {
        this.showFlightLandingFF = value;
      });
    },
  },

  computed: {
    flightRouteName() {
      return this.showFlightLandingFF ? 'flights' : 'concierge';
    },
  },

  watch: {
    ['$route.query.flightNumber']: {
      immediate: true,
      handler(val, oldVal) {
        if (!val && !!oldVal) {
          this.redirect();
        }
      },
    },
  },
};
</script>

<style scoped></style>
